import React from "react"
import styled from "@emotion/styled"

const BBVA = styled.img`
  width: 95px;
  height: 54px;
`

const BBVALogo = () => <BBVA src="/bbva.png" alt="BBVA" />

export default BBVALogo
