import React from "react"
import styled from "@emotion/styled"

const Scotiabank = styled.img`
  width: 101px;
  height: 22px;
`

const ScotiabankLogo = () => (
  <Scotiabank src="/scotiabank.png" alt="Scotiabank" />
)

export default ScotiabankLogo
