import React from "react"
import styled from "@emotion/styled"

const Santander = styled.img`
  width: 103px;
  height: 60px;
`

const SantanderLogo = () => <Santander src="/santander.png" alt="Santander" />

export default SantanderLogo
