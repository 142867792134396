import React, { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import styled from "@emotion/styled"

import CopyIcon from "../ui/images/copy-icon"
import CheckIcon from "../ui/images/check-icon"

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`

const Content = styled.div`
  margin-top: 1rem;
  width: 100%;
`

const ModalField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.5rem 0;
`

const Label = styled.p`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 0.75em;
  line-height: 16px;
  margin: 0;
`

const Value = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
  line-height: 16px;
  margin: 0;
`

const ImgWrapper = styled.div`
  cursor: pointer;
`

const Note = styled.p`
  font-size: 10px;
  line-height: 14px;
  font-family: Open Sans;
  max-width: 271px;
`

const fields: string[] = [
  "accountOwner",
  "rut",
  "accountType",
  "accountNumber",
  "ruc",
  "swift",
  "dollarAccount",
  "ciiDollarAccount",
  "solesAccount",
  "ciiSolesAccount",
  "bankCodeId",
  "intermediaryBank",
  "accountNBR",
  "abaRouting",
  "intermediarySwift",
  "sucursal",
  "email",
]

type Props = {
  logo: any
  account: BankAccount
}

const BankModal = ({ logo, account }: Props) => {
  const [copiedId, setCopiedId] = useState(-1)

  const bankAccount = account as {
    [key: string]: AccountField
  }

  return (
    <Modal>
      {logo}
      <Content>
        {fields.map((f, idx) => {
          if (bankAccount[f]) {
            return (
              <ModalField key={`modal-field${idx}`}>
                <div>
                  <Label>{bankAccount[f].label}</Label>
                  <Value>{bankAccount[f].value}</Value>
                </div>

                <CopyToClipboard
                  text={bankAccount[f].value}
                  onCopy={() => setCopiedId(idx)}
                >
                  <ImgWrapper>
                    {idx === copiedId ? <CheckIcon /> : <CopyIcon />}
                  </ImgWrapper>
                </CopyToClipboard>
              </ModalField>
            )
          }

          return null
        })}
      </Content>
      <Note>
        *Recuerda que una vez realizada la transferencia deberás enviar tu
        comprobante a <strong>depositos@capitaria.com</strong>
      </Note>
    </Modal>
  )
}

export default BankModal
