/** @jsx jsx */
import { useState } from "react"
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import PageLayout from "../ui/page-layout"
import DropdownTabs from "../ui/dropdown-tabs"
import GreenTabs from "../ui/green-tabs"

import ChileDeposit from "./chile-deposit"
import PeruDeposit from "./peru-deposit"
import UruguayDeposit from "./uruguay-deposit"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-bottom: 5rem;
`

const Description = styled.p`
  font-family: Nunito;
  font-size: 18px;
  line-height: 120%;
  color: #1f2425;
  text-align: center;
  padding: 5rem 0 0;
  margin: 0;
  display: none;
  @media (min-width: 1024px) {
    max-width: 533px;
    display: block;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    max-width: 533px;
    display: block;
  }
`

const dropdownTabsContainerCSS = css`
  display: none;
  @media (max-width: 736px) {
    display: block;
  }
`

const greenTabsContainerCSS = css`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

const tabs: TabType[] = [
  {
    text: "Chile",
    value: "chile",
  },
  {
    text: "Perú",
    value: "peru",
  },
  {
    text: "Uruguay",
    value: "uruguay",
  },
]

const Deposit = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  return (
    <PageLayout title="Depósitos">
      <Container>
        <Description>Selecciona tu país y tu forma de depósito.</Description>

        <DropdownTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={clickedTab => {
            setSelectedTab(clickedTab)
          }}
          containerCSS={dropdownTabsContainerCSS}
        />

        <GreenTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={clickedTab => {
            setSelectedTab(clickedTab)
          }}
          containerCSS={greenTabsContainerCSS}
        />

        {selectedTab.value === "chile" ? <ChileDeposit /> : null}
        {selectedTab.value === "peru" ? <PeruDeposit /> : null}
        {selectedTab.value === "uruguay" ? <UruguayDeposit /> : null}
      </Container>
    </PageLayout>
  )
}

export default Deposit
