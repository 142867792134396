import React, { useState } from "react"
import styled from "@emotion/styled"

import BCP from "../ui/images/bcp-logo"
import BBVA from "../ui/images/bbva-logo"
import Scotiabank from "../ui/images/scotiabank-logo"
import BankBox from "./bank-box"
import BankModal from "./bank-modal"
import CustomModal from "../ui/custom-modal"
import PayOnline from "./pay-online"

const DepositWrapper = styled.div`
  padding: 3rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  @media (min-width: 736px) {
    max-width: 690px;
  }
`

const Title = styled.h4`
  color: #1f2425;
  text-align: center;
  line-height: 140%;
`

const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
`

const Banks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    flex-direction: row;
  }
`

const Description = styled.div``

const TitleDescription = styled.h4`
  font-family: "Open Sans";
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
  margin-bottom: 1.25rem;
`

const BCPAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BCP LPEPL",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "1941959486196",
  },
  ciiDollarAccount: {
    label: "CCI (Código Interbancario)",
    value: "00219400195948619695",
  },
  solesAccount: {
    label: "Cuenta Soles",
    value: "1941973495091",
  },
  ciiSolesAccount: {
    label: "CCI (Código Interbancario)",
    value: "00219400197349509198",
  },
  bankCodeId: {
    label: "Código Identificación Bancaria",
    value: "93560477",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const BBVAacount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BCONPEPL",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "0011-0123-01-00063521",
  },
  ciiDollarAccount: {
    label: "CCI (Código Interbancario)",
    value: "011 123 000100063521 77",
  },
  solesAccount: {
    label: "Cuenta Soles",
    value: "0011-0123-01-00063513",
  },
  ciiSolesAccount: {
    label: "CCI (Código Interbancario)",
    value: "011 123 000100063513 74",
  },
  bankCodeId: {
    label: "Código Identificación Bancaria",
    value: "00025317926",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const scotiabankAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  ruc: {
    label: "Ruc",
    value: "9040061493",
  },
  swift: {
    label: "SWIFT",
    value: "BSUDPEPLXXX",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "0003977006",
  },
  ciiDollarAccount: {
    label: "CCI (Código Interbancario)",
    value: "009 170 000003977006 20",
  },
  solesAccount: {
    label: "Cuenta Soles",
    value: "0000451070",
  },
  ciiSolesAccount: {
    label: "CCI (Código Interbancario)",
    value: "009 170 000000451070 27",
  },
}

const PeruDeposit = () => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = (selectedAccount: BankAccount, logo: any) => {
    setIsModalOpen(true)
    setAccount(selectedAccount)
    setLogo(logo)
  }

  return (
    <DepositWrapper>
      <PayOnline international />
      <BankTransference>
        <Title>Transferencias bancarias</Title>

        <Banks>
          <BankBox
            isSolesAndDollarAccount={true}
            onClick={openModal}
            account={BCPAccount}
          >
            <BCP />
          </BankBox>

          <BankBox
            isSolesAndDollarAccount={true}
            onClick={openModal}
            account={BBVAacount}
          >
            <BBVA />
          </BankBox>

          <BankBox
            isSolesAndDollarAccount={true}
            onClick={openModal}
            account={scotiabankAccount}
          >
            <Scotiabank />
          </BankBox>
        </Banks>

        <Description>
          <TitleDescription>
            Condiciones transferencias bancarias cuentas Perú
          </TitleDescription>
          <Text>
            <strong>
              Los depósitos deben enviarse al email depositos@capitaria.com con
              copia a su agente comercial e ir bajo el asunto KT.
            </strong>
          </Text>
          <Text>
            Cabe señalar que{" "}
            <strong>la cuenta del Citibank tiene un costo asociado</strong> por
            ingreso y retiro.
          </Text>
          <Text>
            <strong>
              NO SE ACEPTAN DEPÓSITOS PROVENIENTES DE CUENTAS CORRIENTES DE
              TERCEROS.
            </strong>{" "}
            Si esto sucede, todo depósito será reversado a la cuenta corriente
            inscrita en el contrato.
          </Text>
        </Description>
      </BankTransference>

      <CustomModal
        title="Información para transferencia"
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <BankModal logo={logo} account={account} />
      </CustomModal>
    </DepositWrapper>
  )
}

export default PeruDeposit
