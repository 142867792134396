import React from "react"
import styled from "@emotion/styled"

const BancoEstadoImage = styled.img`
  width: 136px;
  height: 37px;
`

const BancoEstadoLogo = () => (
  <BancoEstadoImage src="/banco-estado.png" alt="Banco Estado" />
)

export default BancoEstadoLogo
