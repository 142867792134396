import React from "react"
import styled from "@emotion/styled"

const Copy = styled.img`
  width: 13px;
  height: 14px;
`

const CopyIcon = () => <Copy src="/copy-icon.png" alt="copy" />

export default CopyIcon
