import React from "react"
import styled from "@emotion/styled"
import { useQueryString } from "@capitaria/capitaria-utms"

import { trackEvent } from "../../helpers/tracker"

const Box = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 1rem 0;
  margin: 1rem 0 2rem;
  width: 217px;
  min-height: 100px;
  cursor: pointer;
`

const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

type Props = {
  children: any
}

const WebpayBox = ({ children }: Props) => {
  const {
    parsedQueryString: { mc_origen: mcOrigen },
    queryString,
  } = useQueryString()

  const onClickWebayNational = () =>
    trackEvent('deposit:webpay-national', { mc_origen: mcOrigen })

  return (
    <>
      <Box
        href={`https://depositos.capitaria.com/${queryString}`}
        onClick={onClickWebayNational}
        target="_blank"
        rel="noopener noreferrer"
      >
        <BoxContent>{children}</BoxContent>
      </Box>
    </>
  )
}

export default WebpayBox
