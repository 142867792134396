import React, { useState } from "react"
import styled from "@emotion/styled"

import BancoEstado from "../ui/images/banco-estado-logo"
import BankBox from "./bank-box"
import BankModal from "./bank-modal"
import CustomModal from "../ui/custom-modal"
import PayOnline from "./pay-online"
import BancoBCI from "../ui/images/banco-bci-logo"

const DepositWrapper = styled.div`
  padding: 3rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  @media (min-width: 736px) {
    max-width: 690px;
  }
`

const Title = styled.h4`
  color: #1f2425;
  text-align: center;
  line-height: 140%;
`

const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
`

const Banks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    flex-direction: row;
  }
`

const Description = styled.div``

const TitleDescription = styled.h4`
  font-family: "Open Sans";
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
  margin-bottom: 1.25rem;
`

const BCCAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountType: {
    label: "Tipo de Cuenta",
    value: "Cuenta Corriente",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "00008079447",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const BCIAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountType: {
    label: "Tipo de Cuenta",
    value: "Cuenta Corriente",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "63516764",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const BEDollarAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "Capitaria Latam SPA",
  },
  rut: {
    label: "RUT",
    value: "76.257.904-9",
  },
  accountNumber: {
    label: "N° de Cuenta",
    value: "001-0-804237-0",
  },
  swift: {
    label: "SWIFT",
    value: "BECHCLRM",
  },
  intermediaryBank: {
    label: "Banco Intermediario (Opcional)",
    value: "BANK OF AMERICA",
  },
  intermediarySwift: {
    label: "SWIFT Banco Intermediario",
    value: "BOFAUS3N",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const ChileDeposit = () => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const selectedAccount = (clikedAccount: BankAccount, logoAccount: any) => {
    setIsModalOpen(true)
    setLogo(logoAccount)
    setAccount(clikedAccount)
  }

  return (
    <DepositWrapper>
      <PayOnline />

      <BankTransference>
        <Title>
          Transferencia bancaria a alguna de nuestras siguientes cuentas:
        </Title>

        <Banks>
          <BankBox onClick={selectedAccount} account={BCCAccount}>
            <BancoEstado />
          </BankBox>

          <BankBox
            isDollarAccount={true}
            onClick={selectedAccount}
            account={BEDollarAccount}
          >
            <BancoEstado />
          </BankBox>

          <BankBox onClick={selectedAccount} account={BCIAccount}>
            <BancoBCI />
          </BankBox>
        </Banks>

        <Description>
          <TitleDescription>
            Condiciones transferencias bancarias cuentas nacionales
          </TitleDescription>
          <Text>
            <strong>NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO</strong>, todo
            depósito en efectivo será devuelto a la cuenta suscrita en el
            contrato de apertura.
          </Text>
          <Text>
            <strong>
              NO SE ACEPTARÁN DEPÓSITOS EN cheque o transferencias bancarias
              provenientes de terceros
            </strong>{" "}
            o cuentas no suscritas al contrato de apertura.
          </Text>
          <Text>
            Todo depósito tendrá hasta 48 horas hábiles para ser reflejado en la
            cuenta de trading.
          </Text>
          <Text>
            <strong>
              La copia de transferencia deberá ser enviada como comprobante de
              depósito a depositos@capitaria.com.
            </strong>
          </Text>
        </Description>

        <Description>
          <TitleDescription>
            Condiciones transferencias bancarias cuentas internacionales
          </TitleDescription>
          <Text>
            <strong>NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO</strong>, todo
            depósito en efectivo será devuelto a la cuenta suscrita en el
            contrato de apertura.
          </Text>
          <Text>
            <strong>
              NO SE ACEPTARÁN DEPÓSITOS EN cheque o transferencias bancarias
              provenientes de terceros
            </strong>{" "}
            o cuentas no suscritas al contrato de apertura
          </Text>
          <Text>
            Todo depósito tendrá hasta 72 horas hábiles para ser reflejado en la
            cuenta de trading.
          </Text>
          <Text>
            <strong>
              La copia de transferencia deberá ser enviada como comprobante de
              depósito al agente comercial.
            </strong>
          </Text>
          <Text>
            <strong>
              Toda transacción entre cuentas internacionales tiene un costo de
              transferencia asociado
            </strong>{" "}
            dependiendo del banco de origen del cliente.
          </Text>
          <Text>
            Para transferencias entre cuentas Citibank, el costo es de 8
            dólares. Para transferencias entre Citibank y cuentas
            internacionales, el costo es de 10 dólares.
          </Text>
          <Text>
            La transacción puede tener costos adicionales provenientes del banco
            de origen del cliente, antes de realizar una transacción
            internacional, informarse con su banco acerca de los costos
            asociados.
          </Text>
        </Description>
      </BankTransference>

      <CustomModal
        title="Información para transferencia"
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <BankModal logo={logo} account={account} />
      </CustomModal>
    </DepositWrapper>
  )
}

export default ChileDeposit
