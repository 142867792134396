import React, { useState } from "react"
import styled from "@emotion/styled"

import Santander from "../ui/images/santander-logo"
import BankBox from "./bank-box"
import BankModal from "./bank-modal"
import CustomModal from "../ui/custom-modal"
import PayOnline from "./pay-online"

const DepositWrapper = styled.div`
  padding: 3rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  @media (min-width: 736px) {
    max-width: 690px;
  }
`

const Title = styled.h4`
  color: #1f2425;
  text-align: center;
  line-height: 140%;
`

const BankTransference = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
`

const Banks = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    flex-direction: row;
  }
`

const Description = styled.div``

const TitleDescription = styled.h4`
  font-family: "Open Sans";
  color: #1f2425;
  line-height: 140%;
`

const Text = styled.p`
  font-family: "Open Sans";
  font-size: 0.75em;
  margin-bottom: 1.25rem;
`

const SantanderAccount: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BSCHUYMMXXX",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "5100566313",
  },
  sucursal: {
    label: "Sucursal",
    value: "04 - Ciudad Vieja",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const SantanderAccountOtherBanks: BankAccount = {
  accountOwner: {
    label: "Beneficiario",
    value: "KT Financial Group Limited",
  },
  swift: {
    label: "SWIFT",
    value: "BSCHUYMMXXX",
  },
  dollarAccount: {
    label: "Cuenta Dólares",
    value: "0004005100566313",
  },
  email: {
    label: "Email de Depósito",
    value: "depositos@capitaria.com",
  },
}

const UruguayDeposit = () => {
  const emptyAccount: BankAccount = {
    accountOwner: { label: "", value: "" },
  }
  const [account, setAccount] = useState(emptyAccount)
  const [logo, setLogo] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = (selectedAccount: BankAccount, logo: any) => {
    setIsModalOpen(true)
    setAccount(selectedAccount)
    setLogo(logo)
  }

  return (
    <DepositWrapper>
      <PayOnline international />
      <BankTransference>
        <Title>Transferencias bancarias</Title>

        <Banks>
          <BankBox
            onClick={openModal}
            account={SantanderAccount}
            isDollarAccount
            extraText
          >
            Desde mi cuenta Santander
            <Santander />
          </BankBox>

          <BankBox
            onClick={openModal}
            account={SantanderAccountOtherBanks}
            isDollarAccount
            extraText
          >
            Desde otros bancos
            <Santander />
          </BankBox>
        </Banks>

        <Description>
          <TitleDescription>
            Condiciones en caso de transferencia
          </TitleDescription>

          <Text>
            La cuenta bancaria que el cliente brinde al momento de la apertura
            de su cuenta, para efectos de depósitos y rescates, deberá ser de la
            misma moneda en la que apertura la cuenta en KTFG. De no ser así,
            KTFG no se responsabilizará por la demora y rechazo de la operación
            de rescate que el cliente solicite, ya que operaciones de
            transferencia interbancaria con diferente moneda no son aceptadas
            por los bancos y estas son rechazadas a los días, retrasando así la
            operación de rescate del cliente.
          </Text>

          <Text>
            <strong>
              Se debe enviar comprobante de transferencia al email
              depositos@capitaria.com, con copia a su Ejecutivo de Inversiones
              y en el asunto debe ir KT depósito.
            </strong>
          </Text>

          <Text>
            <strong>
              Las transferencias de depósito deben de enviarse al email
              depositos@capitaria.com, con copia a su Ejecutivo de Inversiones y
              en el asunto debe ir KT depósito.
            </strong>
          </Text>

          <Text>
            <strong>
              NO SE ACEPTAN DEPÓSITOS PROVENIENTES DE CUENTAS DE TERCEROS.
            </strong>{" "}
            Dicha transferencia deberá ser de la cuenta bancaria que se
            encuentra ya registrada en el sistema. Si esto sucede, se realizará
            un retorno de operación de la transferencia, y se devolverá el dinero
            a la cuenta bancaria de la cual se realizó la transferencia.
          </Text>

          <TitleDescription>
            Condiciones en caso de depósitos
          </TitleDescription>

          <Text>
            <strong>
              NO SE ACEPTARÁN DEPÓSITOS EN EFECTIVO,
            </strong>{" "}
            todo depósito en efectivo será devuelto a la cuenta suscrita en el
            contrato de apertura.
          </Text>

          <Text>
            <strong>
              NO SE ACEPTARÁN DEPÓSITOS DE TERCEROS, ya sea en cheque o
              transferencias bancarias,
            </strong>{" "}
            o desde cuentas bancarias no suscritas al contrato de apertura.
          </Text>

          <Text>
            <strong>
              Se debe enviar foto del cheque del titular de la cuenta, previo
              depósito por buzonera y copia del depósito efectuado al agente
              comercial.
            </strong>
          </Text>

          <Text>
            Todo depósito tendrá hasta 72 horas hábiles para ser reflejado
            en la cuenta de trading.
          </Text>

          <Text>
            <strong>
              Toda transacción entre cuentas internacionales tiene un costo
              de transferencia asociado
            </strong>{" "}
            dependiendo del banco de origen del cliente.
          </Text>

          <Text>
            <strong>
              Argentina:
            </strong>{" "}
            depósitos y retiros de dinero sólo con cuenta bancaria en el extranjero
          </Text>

        </Description>
      </BankTransference>

      <CustomModal
        title="Información para transferencia"
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <BankModal logo={logo} account={account} />
      </CustomModal>
    </DepositWrapper>
  )
}

export default UruguayDeposit
