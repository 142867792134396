import React from "react"
import styled from "@emotion/styled"

const BCP = styled.img`
  width: 98px;
  height: 45px;
`

const BCPLogo = () => <BCP src="/bcp.png" alt="BCP" />

export default BCPLogo
