import React, { ReactNode } from "react"
import styled from "@emotion/styled"

const Alert = styled.div`
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 130.5%;
  color: #000000;
  background-color: #e7eef4;
  border-radius: 5px;
  padding: 1rem 1.5rem;
`

type Props = {
  children: ReactNode
}

const AlertBanner = ({ children }: Props) => <Alert>{children}</Alert>

export default AlertBanner
