import React from "react"
import styled from "@emotion/styled"

import AlertBanner from "../ui/alert-banner"
import WebpayBox from "./webpay-box"
import Webpay from "../ui/images/webpay-logo"
import WebpayBoxInternational from "./webpay-box-internacional"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`

const Title = styled.h4`
  color: #1f2425;
  text-align: center;
  line-height: 140%;
`

type Props = {
  international?: boolean
}

const PayOnline = ({ international = false }: Props) => {
  const WebpayWrapper = international ? WebpayBoxInternational : WebpayBox

  return (
    <Content>
      <Title>
        Depósito con tu tarjeta de crédito{!international && " o débito"}
      </Title>

      <WebpayWrapper>
        <Webpay />
      </WebpayWrapper>

      <AlertBanner>
        <strong>IMPORTANTE:</strong> Por el uso de Transbank, al realizar un
        depósito vía webpay, este tiene un costo de 2,25% con tarjeta de crédito
        y 1% con tarjeta de débito. Dicho costo será descontado del monto
        depositado.
        <br />
        <br />
        <strong>
          NO SE ACEPTAN PAGOS PROVENIENTES DE TARJETAS DE TERCEROS.
        </strong>{" "}
        Si esto sucede, el pago será anulado.
      </AlertBanner>
    </Content>
  )
}

export default PayOnline
