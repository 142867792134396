import React from "react"
import styled from "@emotion/styled"

const BancoBCIImage = styled.img`
  width: 109px;
  height: 59px;
`

const BancoBCILogo = () => (
  <BancoBCIImage src="/bci.png" alt="Banco Estado" />
)

export default BancoBCILogo
