import React from "react"
import styled from "@emotion/styled"

const Webpay = styled.img`
  width: 165px;
  height: 72px;
`

const WebpayLogo = () => <Webpay src="/webpay.png" alt="webpay" />

export default WebpayLogo
