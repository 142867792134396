import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import DepositPage from "../components/deposit-page/deposit-page"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

const Deposit = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="Depósitos" />
      <DepositPage />
    </Layout>
  </QueryStringProvider>
)

export default Deposit
