import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useQueryString } from "@capitaria/capitaria-utms"

import CustomModal from "../ui/custom-modal"
import Button from "../ui/button"
import { trackEvent } from "../../helpers/tracker"

const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 23px 50px 50px;
  width: 700px;
  @media (max-width: 720px) {
    max-width: 500px;
    width: 100%;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
`

const TitleModal = css`
  background: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;
`

const Subject = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
`

const Description = styled.div`
  font-size: 13px;
`

const Details = styled.div`
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 45px;
`

const buttonCSS = css`
  width: initial;
  font-size: 13.3px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
`

const ModalChildren = () => {
  const {
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()

  const onClickWebayInternational = () =>
    trackEvent(`deposit:webpay-international`, { mc_origen: mcOrigen })

  return (
    <ContainerModal>
      <Content>
        <img
          src="/image-pay-international.png"
          alt="Pagos mediante Webpay internacional"
        />
        <Details>
          <Subject>
            ¿Cómo funciona el pago con tarjeta de crédito en Capitaria?
          </Subject>
          <Description>
            Hola! Para realizar tu depósito con tarjeta utilizamos el sistema
            chileno <strong>Webpay,</strong> por lo que por requerimientos de
            ellos tenemos que mostrar el valor en pesos chilenos.
            <br />
            <br />
            Por el uso de Transbank, al realizar un depósito vía webpay, este
            tiene un costo de 2,25% con tarjeta de crédito. Dicho costo será
            descontado del monto depositado.
          </Description>
        </Details>
        <Button
          href="http://depositos.capitaria.com/internacional"
          onClick={onClickWebayInternational}
          styleCSS={buttonCSS}
          target="_blank"
        >
          IR A DEPOSITAR
        </Button>
      </Content>
    </ContainerModal>
  )
}

type Props = {
  isModalOpen: boolean
  closeModal: () => void
}

const ModalWebpayInternational = ({ isModalOpen, closeModal }: Props) => (
  <CustomModal
    title="Acerca del pago con Tarjeta de Crédito"
    isOpen={isModalOpen}
    closeModal={closeModal}
    styleCSS={TitleModal}
  >
    <ModalChildren />
  </CustomModal>
)

export default ModalWebpayInternational
